<template>
  <v-container class="fill-height pa-0 primary1" style="color: #efefef" fluid>
    <v-row class="fill-height" no-gutters>
      <v-col v-if="isDesktop" class="fill-height pa-0" cols="12" md="6">
        <v-img
          class="fill-height"
          max-height="100vh"
          alt="WithU Active Woman"
          cover
          src="@/assets/home-left.jpg"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="fill-height d-flex align-center justify-center"
        style="padding: 80px 20px; position: relative"
      >
        <div
          class="d-flex flex-column text-center align-center"
          style="max-width: 450px; font-size: 14px"
        >
          <font-awesome-icon
            :icon="checkIcon"
            size="5x"
            :style="{ color: 'var(--v-secondary-base)' }"
          />
          <div class="font-weight-bold text-h6 my-4" style="color: #efefef">
            {{
              subscriptionId
                ? "Thanks for your order"
                : "Thanks for joining WithU"
            }}
          </div>
          <div class="font-weight-regular" style="color: #bcbcbc">
            {{
              subscriptionId
                ? "You’ll receive an email confirmation shortly at"
                : "You’ll receive a welcome email shortly at"
            }}
          </div>
          <div class="font-weight-bold" style="color: #efefef; font-size: 14px">
            {{ email }}
          </div>
          <div v-if="!isDesktop">
            <v-btn
              color="secondary"
              style="height: 55px"
              class="black--text font-weight-bold text-subtitle-1 px-10 mt-10 mb-2"
              @click="download"
              :loading="loading"
            >
              Set up your account
            </v-btn>
            <div style="width: 300px; color: #bcbcbc">
              Download the app then login
            </div>
          </div>

          <div v-else class="my-10 d-flex align-center flex-column">
            <div class="pb-2" style="width: 300px; color: #bcbcbc">
              Enter your number below to recieve a download link for the app
            </div>
            <div class="d-flex">
              <vue-tel-input
                class="ml-8"
                v-model="phone"
                @validate="validate"
                style="color: white; min-width: 250px"
              ></vue-tel-input>
              <v-btn
                @click="sendSMS"
                color="secondary"
                icon
                :disabled="!valid || loading"
                dark
                :loading="loading"
                ><v-icon>mdi-send</v-icon></v-btn
              >
            </div>
            <div class="my-2" style="width: 300px; color: #bcbcbc">
              Download the app then login
            </div>
            <v-alert
              v-if="sent"
              type="success"
              border="left"
              text
              dense
              class="ma-0 my-3 mx-8"
              dismissible
              style="max-width: 500px"
            >
              Sent SMS
            </v-alert>
            <v-alert
              v-else-if="error"
              type="error"
              border="left"
              text
              dense
              class="ma-0 my-3 mx-8"
              dismissible
              style="max-width: 500px"
            >
              Error sending SMS
            </v-alert>
          </div>
        </div>
        <v-alert
          :style="`position: absolute; top: ${
            isDesktop ? 0 : 50
          }px; left: 0px; right: 0px`"
          class="ma-0"
          type="warning"
          text
          dense
          v-if="showEmailError"
          >We are experiencing delays when sending emails to: Sky, Yahoo, AOL
          and Rocket email addresses. Please wait 30 minutes for your email to
          come through befoore contacting support</v-alert
        >
        <div
          style="position: absolute; bottom: 0"
          class="text-center pa-2 text-caption"
        >
          <div v-if="subscriptionId">
            <div class="font-weight-regular" style="color: #bcbcbc">
              Subscription ID:
              <span>{{ subscriptionId }}</span>
            </div>
          </div>
          <div class="font-weight-regular" style="color: #bcbcbc">
            User ID: <span>{{ userId }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

export default {
  name: "Download",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      error: false,
      sent: false,
      checkIcon: faCheckCircle,
      phone: null,
      valid: false,
      number: null,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    ...mapGetters({
      originalLink: "account/originalLink",
    }),
    successfulVerify() {
      var verify = this.account.download;

      return verify.link != null && verify.code != null;
    },

    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    loading() {
      return this.account.loading;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    userId() {
      return this.account.register.user_id;
    },
    subscriptionId() {
      if (this.account.stripe) {
        if (this.account.stripe.subscription) {
          return this.account.stripe.subscription.id;
        } else if (this.account.stripe.invoice) {
          return this.account.stripe.invoice.subscription;
        }
      }
      return null;
    },
    email() {
      return this.account.register.email;
    },
    showEmailError() {
      if (!this.email) return false;

      var arr = ["ymail", "yahoo", "aol", "sky", "rocketmail"];

      var hasDomain = false;
      arr.forEach((d) => {
        if (this.email.includes(d)) {
          hasDomain = true;
        }
      });

      return hasDomain;
    },
  },
  methods: {
    ...mapActions({
      verify: "account/verifyCode",
      setLoading: "account/setLoading",
      sendText: "account/sendText",
      getDownloadLink: "account/downloadLink",
    }),

    validate(v) {
      this.number = v.number;
      this.valid = v.valid;
    },

    async getLink() {
      // var link = this.originalLink;
      var link;
      if (link) {
        if (link.includes("&")) {
          link += "&";
        } else {
          link += "?";
        }
        link += `email=${this.email}&mode=web&$web_only=false`;
      } else {
        link = `https://app.withutraining.com/login?email=${this.email}&mode=web&$web_only=false`;
      }
      if (this.originalLink) {
        link += `&~referring_link=${this.originalLink}`;
      }
      return link;

      // var data = {
      //   mode: "web",
      //   $deeplink_no_attribution: true,
      //   email: this.email,
      // };
      // if (this.originalLink) {
      //   data["~referring_link"] = this.originalLink;
      // }

      // return new Promise((resolve) => {
      //   branch.link(
      //     {
      //       channel: "my.withutraining",
      //       feature: "login",
      //       data: data,
      //     },
      //     function (err, link) {
      //       console.log(err, link);
      //       resolve(link);
      //     }
      //   );
      // });
    },

    async download() {
      var link = await this.getLink();
      if (link) {
        window.open(link, "_blank");
      } else {
        this.error = true;
      }
      this.setLoading(false);
    },

    sendSMS1() {
      this.error = false;
      this.sent = false;

      let self = this;
      branch.sendSMS(
        this.number,
        {
          channel: "my.withutraining",
          feature: "Text-Me-The-App",
          data: {
            $custom_sms_text:
              "Please click on the link below to download WithU and activate your subscription;\n{{ link }}\n\nClick Log In to get started.",
            email: this.email,
            mode: "web",
            $web_only: false,
          },
        },
        { make_new_link: true },
        function (err) {
          console.log(err);
          if (err) {
            self.error = true;
          } else {
            self.sent = true;
          }
          self.setLoading(false);
        }
      );
    },

    async sendSMS() {
      this.error = false;
      this.sent = false;

      var link = await this.getLink();
      if (link) {
        var result = await this.sendText({
          number: this.number,
          url: link,
        });
        if (!result) {
          this.error = true;
        } else {
          this.sent = true;
        }

        this.setLoading(false);
      } else {
        this.error = true;
        this.setLoading(false);
      }
    },
  },
  beforeMount() {
    // this.getDownloadLink();
    this.setLoading(false);
  },
};
</script>

<style>
.vue-tel-input * {
  color: white !important;
}

.vti__dropdown-list.below {
  padding-left: 0px;
}

.vti__dropdown-list.below * {
  color: black !important;
}
</style>

<style scoped>
.curved-container {
  width: 150%;
  height: 50%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  bottom: 0px;
  left: -25%;
  position: absolute;
  z-index: 0;
}

.centered-input >>> * {
  text-align: center;
  color: white !important;
}
</style>

